<template>

  <div v-if="session.vendor != null">

    <div class="ec_co_vendor_head">

    <v-img class="ec_co_vendlogo" :src="session.vendor.logo" lazy-src=""/>
    <span class="ec_co_vendname">{{ session.vendor.name }}</span>
    <span class="ec_co_vendaddr">{{ session.vendor.address.address_1 }}, {{ session.vendor.address.city }}</span>

    <!--<pre>{{session.vendor}}</pre>-->

    </div>

  </div>

</template>

<script>

import {mapState} from "vuex";

export default {

  computed: {


    ...mapState(['session','cart'])

  },


}

</script>

<style>

.ec_co_vendor_head {
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: 100px auto;
  grid-column-gap: 15px;
  border-bottom: 1px solid #dadada;
}

.ec_co_vendlogo {
  width: 100px;
  float: left;
  grid-column: 1;
  grid-row: 1 / span 4;
}

.ec_co_vendname {
  grid-column: 2;
  font-weight: bold;
  margin-top: 5px;
}

.ec_co_vendaddr {
  grid-column: 2;
  font-size: 14px;
}

</style>