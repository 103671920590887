<template>

  <v-card class="ec_checkout_container" flat>

    <div class="ec_co_vendor_head" v-if="session.vendor != null">
      <v-img class="ec_co_vendlogo" :src="session.vendor.logo" lazy-src=""/>
      <span class="ec_co_vendname">{{ session.vendor.name }}</span>
      <span class="ec_co_vendaddr">{{ session.vendor.address.address_1 }}, {{ session.vendor.address.city }}</span>

      <router-link class="ec_co_backtovend"
                   :to="{ name: 'restaurant', params: { slug: $route.params.slug }}">
        <v-btn outlined color="#027d88">
          <v-icon>mdi-arrow-left-bold</v-icon>
          Torna al menu
        </v-btn>
      </router-link>

    </div>

    <v-alert color="amber lighten-4" dismissible>
      Il nostro personale è equipaggiato con mascherine di grado chirurgico o superiore, guanti in lattice o nitrile ed è sottoposto a controllo della temperatura corporea.
      <br>
      Per la sicurezza di tutti invitiamo i gentili clienti a prendere responsabilmente pari misure di protezione in fase di ritiro dell'ordine.
    </v-alert>

    <div class="ec_checkout_loaded" v-if="cart.order.items.length > 0">

      <v-card-text class="ec_co_card">

        <h3>1. Preferenze sull'ordine</h3>

        <v-card class="ec_checkout_block two-col">

        <shipping_methods></shipping_methods>

        <payment_methods></payment_methods>

        </v-card>

      </v-card-text>

      <v-card-text class="ec_co_card">

        <h3>2. Prendi appuntamento</h3>

        <div class="ec_checkout_block">
          <v-icon class="abs_icon">mdi-information-outline</v-icon>
          <p>Seleziona il giorno e la fascia oraria preferita tra quelle ancora disponibili. Ricorda che, anche se il 90% degli ordini viene consegnato in orario, la fascia selezionata è da considerarsi indicativa, in quanto a seconda della quantità di ordini in lavorazione, si potrebbero verificare ritardi nella preparazione da parte del ristorante o nella consegna a domicilio.</p>

          <availability_selector></availability_selector>

          <div v-if="cart.order.desired_delivery_time_from" class="ec_co_appointment">

            <span style="color: #da3856">Il tuo appuntamento:</span>
            {{cart.order.desired_delivery_time_from | moment('DD.MM.Y HH:mm')}} - {{cart.order.desired_delivery_time_to | moment('HH:mm')}}
          </div>

        </div>

      </v-card-text>

      <v-card-text class="ec_co_card">

        <h3>3. Inserisci i dati</h3>

        <div class="ec_checkout_block">
          <v-icon class="abs_icon">mdi-lock-check</v-icon>
          <p>I tuoi dati <a href="/privacy" target="_blank">sono al sicuro</a>.
          <br>
            Fai particolare attenzione ad aver inserito <strong>indirizzo</strong> e <strong>email</strong> corretti!<br>
          La gestione di magentadelivery declina ogni responsabilità per ordini la cui consegna si renda impossibile a causa di errori e/o mancanze in fase di inserimento dell'indirizzo.</p>

          <v-card>
            <address_editor address_type='billing' v-if="!cart.loading_countries && cart.shipping_countries.length > 0"></address_editor>
          </v-card>


          <v-checkbox class="ec_checkout_acceptance"
              v-model="cart.terms_accepted"
              label="Accetto le condizioni di servizio e di privacy">
          </v-checkbox>

          <v-btn
              class="ec_final_checkout_confirm_button"
              block x-large color="#027d88"
              @click="placeOrder()"
              :loading="cart.saving"
              :disabled="!cart.terms_accepted"
          >
            EFFETTUA ORDINE
          </v-btn>

          <order_errors_snackbar></order_errors_snackbar>

        </div>

      </v-card-text>

    </div>

    <div class="ec_co_exp_ord" v-else>

      Non c'è nessun ordine da processare: riprova.

    </div>

  </v-card>

</template>

<script>

import { mapState } from 'vuex';
import address_editor from './infra/checkout/address'
import shipping_methods from './infra/checkout/shipping_methods'
import payment_methods from './infra/checkout/payment_methods'
import availability_selector from './infra/checkout/availability_selector'
import order_errors_snackbar from './infra/checkout/order_errors_snackbar'


export default {

  components: {

    address_editor,
    shipping_methods,
    payment_methods,
    availability_selector,
    order_errors_snackbar,
  },

  mounted: function(){


    this.$store.dispatch('cart/getMethods');
    this.$store.dispatch('cart/loadShippingCountries');
    let vendor = this.$route.params.slug;

    this.$store.dispatch('session/loadVendor', vendor);

  },

  computed: {

    ...mapState(['session', 'cart'])

  },

  methods: {

    placeOrder(){

      if(this.cart.order.ready_for_checkout.ready !== true){

        this.cart.show_errors = true;

      } else {

        window.fbq('track', 'Purchase', {currency: this.cart.order.currency, value: this.cart.order.total_with_tax});
        this.$store.dispatch('cart/place_order');

      }

    }

  }

}

</script>

<style>

.ec_co_vendor_head {
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: 100px auto;
  grid-column-gap: 15px;
  border-bottom: 1px solid #dadada;
}

.ec_co_vendlogo {
  width: 100px;
  float: left;
  grid-column: 1;
  grid-row: 1 / span 4;
}

.ec_co_vendname {
  grid-column: 2;
  font-weight: bold;
  margin-top: 5px;
}

.ec_co_vendaddr {
  grid-column: 2;
  font-size: 14px;
  margin-bottom: 5px;
}

.ec_co_backtovend {
  width: fit-content;
  grid-column: 2;
  text-decoration: none;
  margin-top: 5px;
}

.ec_co_backtovend i {
  padding-right: 5px;
}

.ec_co_exp_ord {
  padding: 20px;
}

.ec_checkout_loaded {
  padding: 20px 20px 10px;
}

.ec_co_card {
  padding: 10px 0 20px;
}

.ec_checkout_block.two-col {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  padding: 0 20px;
  margin: 10px 0 0;
}

.ec_co_card h3 {
  padding-bottom: 10px;
}

.ec_checkout_block {
  position: relative;
  padding: 0 0 20px 35px;
  margin-bottom: 0 !important;
}

.ec_checkout_block .abs_icon {
  position: absolute !important;
  left: 0;
}

.ec_co_appointment {
  font-weight: bold;
  margin: 30px 0 0;
  padding: 15px 10px;
  background-color: #f6f5f5;
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
}

.ec_final_checkout_confirm_button {
  color: white !important;
}

@media (max-width: 600px) {
  .ec_checkout_block {
    padding: 0;
  }
  .ec_checkout_block p {
    padding-left: 35px;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0;
  }
  .ec_co_backtovend button {
    font-size: 12px !important;
  }
  .ec_co_backtovend i {
    font-size: 18px !important;
  }
  .ec_checkout_block.two-col {
    grid-template-columns: 100%;
  }
  .ec_checkout_acceptance label {
    font-size: 14px;
  }
}

</style>