<template>

  <div class="general payment_methods">

    <br><strong>Metodo di pagamento</strong>
    <br>Con PayPal puoi pagare con il tuo conto o con carta di credito anche se non sei registrato.

    <v-radio-group v-model="selected_method">
      <v-radio
          color="#027d88"
          :disabled="cart.processing_payment_method == method.id"
          v-for="method in cart.payment_methods" :key="method.id"
          :label="method.name"
          :value="method.slug"
          @click="selectPaymentMethod(method)"></v-radio>
    </v-radio-group>

  </div>

</template>

<script>

import { mapState } from 'vuex';

export default {

  name: 'payment_methods',
  computed: {


    ...mapState(['cart']),


  },

  data: () => ({

    selected_method: null


  }),


  mounted: function(){

    if(this.cart.order.payment_method != null){

      this.selected_method = this.cart.order.payment_method;

    }

  },

  methods: {


    isSelected(method){

      if(method.slug == this.cart.order.payment_method_id){
        return true;
      }


    },
    selectPaymentMethod(method){


      this.$store.dispatch('cart/selectPaymentMethod',method);



    }

  }

}

</script>

<style>

.general.payment_methods {
  grid-column: 2;
}

@media (max-width: 600px) {
  .general.payment_methods {
    grid-column: 1;
  }
}

</style>