<template>

  <div class="general address">

    <v-layout row>

      <v-flex xs12 md6>
        <v-text-field color="#027d88" label="Nome" class="ma-3" v-model="address.first_name" @blur="updateAddress()" required></v-text-field>
      </v-flex>

      <v-flex xs12 md6>
        <v-text-field color="#027d88" label="Cognome" class="ma-3" v-model="address.last_name" @blur="updateAddress()" required></v-text-field>
      </v-flex>

      <v-flex xs12 md6 v-if="!is_pickup_order">
        <v-text-field color="#027d88" label="Indirizzo e numero civico" class="ma-3" v-model="address.address_1" @keyup="updateAddress()" required ></v-text-field>
      </v-flex>

      <v-flex xs12 md6 v-if="!is_pickup_order">
        <v-text-field color="#027d88" label="CAP" class="ma-3" v-model="address.postcode" @blur="updateAddress()" required ></v-text-field>
      </v-flex>

      <v-flex xs12 md6 v-if="!is_pickup_order">

        <v-select color="#027d88" label="Città" class="ma-3" :items="cart.served_cities" @change="updateAddress()" v-model="address.city" v-if="cart.served_cities.length > 0" required></v-select>
        <v-text-field color="#027d88" label="City" class="ma-3" v-model="address.city" @blur="updateAddress()" v-else required></v-text-field>

        <div v-if="address.city != null && address.city != ''" id="ec_form_output_ship_cost">

          <currency-input :value="cart.order.shipping_total + cart.order.shipping_tax" :currency="cart.order.currency" locale="de" disabled/>

          <span>Seleziona la città per visualizzare il costo di consegna effettivo.</span>

        </div>

      </v-flex>

      <v-flex xs12 md6 v-if="!is_pickup_order">
        <v-text-field color="#027d88" label="Info aggiuntive" class="ma-3" v-model="address.address_2" @blur="updateAddress()"></v-text-field>
      </v-flex>

      <v-flex xs12 md6>
        <v-text-field color="#027d88" label="Telefono" class="ma-3" v-model="address.phone" @blur="updateAddress()" required></v-text-field>
      </v-flex>

      <v-flex xs12 md6>
        <v-text-field color="#027d88" label="E-Mail" class="ma-3" v-model="address.email" @keyup="updateAddress()" required></v-text-field>
      </v-flex>

      <v-flex xs12 md6 class="ec_hidden">

        <v-select color="#027d88" class="ma-3" v-model="address.country" item-value="value" :items="cart.shipping_countries" @change="updateAddress()" required></v-select>

      </v-flex>

    </v-layout>

  </div>

</template>

<script>


import {mapGetters, mapState} from 'vuex'

export default {

  data: () => ({

    address: {

      'first_name': '',
      'last_name': '',
      'company': '',
      'address_1': '',
      'address_2': '',
      'postcode': '',
      'city': '',
      'country': '',
      'email': '',
      'phone': ''

    },

    update_timeout: null

  }),

  props: {


    address_type: {
      default: function () {
        return 'billing';
      }
    }

  },

  computed: {

    ...mapState(['cart']),
    ...mapGetters('cart',['getShippingMethod']),


    is_pickup_order(){


      let method = this.getShippingMethod;


      if(method == false){return false;}


      if(method.shipping_method_type == 'pickup'){return true;}

      return false;

    }


  },


  mounted: function () {


    this.address = this.cart.order[this.address_type + '_address'];
    this.address.country = this.cart.shipping_countries[0].value;
    this.$store.dispatch('cart/loadServedCities');

  },


  methods: {



    sendUpdate() {


      let self = this;

      switch (this.address_type) {

        case 'billing':

          self.$store.dispatch('cart/updateAddress', {'billing_address': self.address});

          break;

        case 'shipping':

          self.$store.dispatch('cart/updateAddress', {'shipping_address': self.address});

          break;

      }


    },
    updateAddress() {


      let self = this;

      clearTimeout(self.update_timeout);


      self.update_timeout = setTimeout(function(){self.sendUpdate()},100);


    }

  }


}


</script>

<style>

.general.address {
  padding: 10px 25px 5px;
  margin-bottom: 20px;
}

.general.address .ma-3 {
  margin: 0 10px !important;
}

#ec_form_output_ship_cost {
  margin: 0 12px 15px;
  text-align: left;
  background-color: #f6f5f5;
  padding: 10px 15px;
}

#ec_form_output_ship_cost input {
  font-weight: bold;
  width: 100%;
  font-size: 16px;
  padding: 5px 0;
  color: black;
}

</style>