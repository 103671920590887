<template>

  <div class="cart_item">

    <div class="item_head pa-3">

            <span class="item_quantity">
                <v-btn icon x-small @click="increaseQuantity()" :disabled="cart.saving"><v-icon>mdi-plus</v-icon></v-btn>
                <v-btn icon x-small @click="decreaseQuantity()" :disabled="cart.saving"><v-icon>mdi-minus</v-icon></v-btn></span>
      <span class="item_name">
        {{ item.quantity }}x {{ item.name }}
                </span>

      <span class="item_price text-right">
                <currency-input :value="item.total_with_tax + item.extra_total_with_tax" :currency="item.currency" locale="de" disabled/>
      </span>

      <span class="item_edit_remove">
        <v-btn icon x-small @click="removeItem()" :disabled="cart.saving"><v-icon color="#da3856">mdi-close</v-icon></v-btn>
        <v-btn icon x-small @click="toggleNoteEditor()" :disabled="cart.saving"><v-icon small>mdi-pencil</v-icon></v-btn>
      </span>

    </div>

    <div class="item_meta">

      <v-card-text class="pt-0">

        <div class="item_extras">

          <div v-for="extra in item.extras" :key="extra.id">

            {{extra.name}} x {{extra.quantity}}

          </div>



        </div>

        <div class="item_note">{{ item.note }}</div>

      </v-card-text>

    </div>

    <div class="item_note_editor" v-if="editing_note">
      <v-textarea class="ec_cart_product_note" placeholder="Scrivi qui le tue note per questo prodotto" hide-details v-model="note_text"></v-textarea>
      <v-btn text small @click="updateNote()" :disabled="cart.saving">Salva</v-btn>
      <v-btn text small @click="deleteNote()" :disabled="cart.saving">Cancella</v-btn>
    </div>

    <v-divider class="ec_cart_divider"></v-divider>

  </div>

</template>

<script>


import {mapState} from "vuex";

export default {

  props: {

    item: {
      default: function () {
        return [];
      }
    }

  },

  computed: {

    ...mapState(['session','cart']),

  },

  data: () => ({

    editing_note: false,
    note_text: null


  }),


  methods: {

    increaseQuantity() {


      this.item.quantity++;


      this.$store.dispatch('cart/updateItemQuantity', {quantity: this.item.quantity, item_id: this.item.id});


    },

    decreaseQuantity() {


      if (this.item.quantity == 1) {
        return;
      }

      this.item.quantity--;


      this.$store.dispatch('cart/updateItemQuantity', {quantity: this.item.quantity, item_id: this.item.id});


    },

    removeItem() {


      this.$store.dispatch('cart/removeItem', {item_id: this.item.id});

    },

    toggleNoteEditor() {

      this.$emit('open-note');

      if (this.editing_note == false) {

        this.note_text = this.item.note;

      }


      this.editing_note = !this.editing_note;

    },

    closeNoteEditor() {

      this.editing_note = false;

    },

    updateNote() {


      this.$store.dispatch('cart/updateItemNote', {note: this.note_text, item_id: this.item.id});

      this.item.note = this.note_text;
      this.closeNoteEditor();


    },

    abortNote() {

      this.note_text = this.item.note;
      this.closeNoteEditor();


    },

    deleteNote() {


      this.item.note = null;
      this.note_text = null;


      this.updateNote();


    }

  }


}


</script>

<style lang="scss" scoped>

.general.cart .pt-0 {
  padding: 0;
}

.cart_item {
  padding: 0 10px;
}

.ec_cart_divider {
  padding-top: 10px;
  margin-top: 5px;
}

.item_note_editor {
  margin: 10px 0;
  font-size: 14px;
}

.item_note {
  font-style: italic;
}

.ec_cart_product_note {
  background-color: white;
  border: 1px solid #dadada;
  font-size: 14px;
  align-items: center;
  padding: 10px 15px 0 !important;
  margin: 0 15px 5px;
}

.cart_item .item_head {

  padding: 0 !important;
  display: grid;
  grid-template-columns: 20% 18.5% 20% 30% 9%;
  grid-column-gap: 0.625%;

  .item_quantity {
    grid-column: 1;
  }

    .item_quantity button {
      background-color: #f2f2f2;
      margin-right: 5px;
    }

    .item_quantity button i {
      font-size: 14px;
    }

  .item_name {
    font-weight: bold;
    grid-row: 1;
    grid-column: 2 / span 3;
  }

  .item_edit_remove {
    grid-row: 1 / span 99;
    grid-column: 5;
    text-align: right;
  }

  .item_edit_remove i {
    color: #027d88;
  }

  .item_price {
    grid-row: 2;
    grid-column: 2;
    text-align: left !important;
  }

  .item_price input {
  }
}

.item_meta {

  padding: 5px 0 5px 20%;

  .item_note,
  .item_extras {
    font-size: 12px;
  }
}


</style>