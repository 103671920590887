<template>

  <div class="ec_ordconf_ordercontents">

    <h2>
      Il tuo ordine
    </h2>

    <div class="ec_orderconf_prod_grid" v-for="item in cart.order.items" :key="item.id">

      <span>{{ item.quantity }}x {{ item.name }}</span>
      <span>

          <currency-input :value="item.total_with_tax + item.extra_total_with_tax" :currency="item.currency" locale="de" disabled/>

      </span>


      <!--
      <div class="item_meta">

        <v-card-text class="pt-0">

          <div class="item_extras">

            <div v-for="extra in item.extras" :key="extra.id">

              {{extra.name}} x {{extra.quantity}}

            </div>



          </div>

          <div class="item_note">{{ item.note }}</div>

        </v-card-text>

      </div>
      -->

    </div>

  </div>

</template>

<script>

import { mapState } from 'vuex';

export default {

  name: 'OrderCONTENTS',
  computed: {


    ...mapState(['cart']),


  },

}

</script>

<style lang="scss">

.ec_ordconf_ordercontents h2 {
  padding: 10px 10px 0 !important;
  font-size: 16px;
}

.ec_orderconf_prod_grid {
  font-size: 14px;
  border-bottom: 1px solid #dadada;
  padding: 10px;
  display: flex !important;
}

.ec_orderconf_prod_grid span {
  width: 100%;
}

.ec_orderconf_prod_grid input {
  text-align: right !important;
}


</style>