<template>

  <div class="general cart_contents">

    <v-card-text class="px-0">

      <template v-for="item in cart.order.items">

        <cart_item :item="item" :key="item.id"></cart_item>

      </template>
    </v-card-text>

    <v-simple-table>

      <tfoot>

      <tr class="ec_cart_subtotal" v-if="cart.order.items.length > 0">

        <td colspan="3" class="text-left">Totale prodotti</td>
        <td class="text-right">

          <currency-input :value="cart.order.cart_total + cart.order.cart_tax" :currency="cart.order.currency"
                          locale="de" class="text-right" disabled/>
        </td>

      </tr>

      <tr class="ec_cart_shipmeth" v-if="cart.order.items.length > 0 && isCheckout">

        <td colspan="3" class="text-left">

          {{ cart.order.shipping_method_name }}

        </td>

        <td class="text-right" v-if="!is_pickup_order">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="#027d88" v-bind="attrs" v-on="on">mdi-car-info</v-icon>
            </template>
            <span>Per visualizzare il costo di consegna effettivo,<br>
              dovrai selezionare la città di destinazione.<br>
              Il ritiro è sempre gratuito.</span>
          </v-tooltip>

        </td>
      </tr>

      <template v-if="cart.order.items.length > 0">

        <tr class="ec_cart_fees" v-for="fee in cart.order.fees" :key="fee.id">

          <td colspan="3">

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Un piccolo contributo che ci permette<br>di mantenere attiva la piattaforma</span>
            </v-tooltip>

            {{fee.name}}

          </td>

          <td class="text-right">

            <currency-input :value="fee.total_with_tax " :currency="cart.order.currency"
                            locale="de" class="text-right" disabled/>

          </td>

        </tr>

      </template>

      <tr class="ec_cart_ord_total" v-if="cart.order.items.length > 0 && isCheckout">

        <td colspan="3">Totale</td>
        <td class="text-right">
          <currency-input :value="cart.order.total_with_tax" :currency="cart.order.currency" locale="de" class="text-right" disabled/>
        </td>

      </tr>

      <tr class="minord_tr" v-if="cart.order.min_order_value > 0 && !min_order_reached">

        <td colspan="3">Ordine minimo</td>
        <td class="text-right">
          <currency-input :value="cart.order.min_order_value" :currency="cart.order.currency" locale="de"
                          class="text-right" disabled/>
        </td>

      </tr>

      <tr class="ec_cart_paymeth" v-if="isCheckout">

        <td colspan="3">Metodo di pagamento</td>
        <td class="text-right">{{ cart.order.payment_method_name }}</td>

      </tr>

      </tfoot>

    </v-simple-table>


    <div class="ec_cart_checkoutinstr" v-if="cart.order.items.length > 0 && isRestaurant && min_order_reached && cart.order.items.length > 0">

      Prosegui al checkout per visualizzare le opzioni di consegna ed i relativi costi. <strong>Ricorda:</strong> il ritiro d'asporto su appuntamento è gratuito!

    </div>

    <v-card-text class="ec_cart_minimum_order_not_reached"
                 v-if="!min_order_reached && cart.order.items.length > 0">
      Non hai raggiunto l'importo minimo di ordine
    </v-card-text>


  </div>

</template>

<script>
import {mapGetters, mapState} from 'vuex';
import cart_item from './cart_item'

export default {


  name: 'cart_contents',
  components: {

    cart_item

  },
  computed: {

    ...mapState(['session','cart']),
    ...mapGetters('cart',['getShippingMethod']),

    is_pickup_order(){


        let method = this.getShippingMethod;


        if(method == false){return false;}


        if(method.shipping_method_type == 'pickup'){return true;}

        return false;

      },

    isRestaurant(){

      if(this.$route.name == 'restaurant'){return true;}

      return false;

    },


    isCheckout(){


      if(this.$route.name == 'checkout'){return true;}

      return false;
    },
    min_order_reached(){


      /*if( this.cart.order.cart_total + this.cart.order.cart_tax < this.cart.order.min_order_value &&
          this.cart.order.min_order_value > 0 && this.cart.order.items.length > 0){

        return false;

      }*/


      if(this.cart.order.items.length ==  0 && this.cart.order.min_order_value > 0){return false;}

      if(this.cart.order.cart_total + this.cart.order.cart_tax < this.cart.order.min_order_value &&
          this.cart.order.min_order_value > 0){
        return false;
      }

      return true;

    }

  },



}

</script>

<style>

.cart_contents {
  padding-top: 10px;
}

.cart_contents tr td {
  vertical-align: top;
}

.ec_cart_minimum_order_not_reached {
  text-align: center;
  font-size: 12px;
  padding: 5px 10px;
  background-color: #da3756;
  color: white;
}

.ec_cart_checkoutinstr {
  padding: 0 10px 15px;
  font-size: 12px;
  line-height: 1.5;
}

.minord_tr {
  font-weight: bold;
  color: #da3856;
}

.ec_cart_fees i {
  vertical-align: text-bottom !important;
}



</style>