<template>

  <div class="ec_service_output">

    <div v-if="bookableDays.length != 0">

      <div class="ec_dyinf_no_availability for_today" v-if="!cartHasSlotsToday">
        <span>Puoi preordinare da questo menu per i prossimi giorni. Cambia menu per la consegna in data odierna.</span>
      </div>

      <div v-for="slot in cart.order.times" :key="slot.date">

        <span class="ec_service_output_span" v-if="slot.times.filter(time => time.is_in_past == false).length > 0">

          <span class="ec_service_output_span today" v-if="slot.is_today">
            Oggi
          </span>

          <span class="ec_service_output_span date" v-else>
            {{ slot.date }}
          </span>

          <div class="ec_time_hours" v-for="(time,index) in slot.times" :key="index">

            <span class="ec_service_output_span" v-if="time.is_in_past == false">
              {{ time.from_time }} - {{ time.to_time }}
            </span>

          </div>

        </span>

      </div>

    </div>

    <div v-else>

      <div class="ec_dyinf_no_availability ever">

        Questo venditore al momento non accetta ordini per questo menu.

      </div>

    </div>

  </div>

</template>

<script>

import {mapState, mapGetters} from 'vuex';

export default {

  name: 'delivery_hours',
  computed: {

    ...mapState(['session', 'menu', 'cart']),
    ...mapGetters('cart',['bookableDays']),
    ...mapGetters('cart',['cartHasSlotsToday']),


  },
}

</script>

<style>

.ec_dyinf_no_availability.for_today {
  margin-bottom: 10px;
}

.ec_dyinf_no_availability.ever {
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #f2f2f2;
}

#ec_overbooked {
  padding-bottom: 10px;
}

#ec_overbooked i {
  padding-right: 5px;
  font-size: 20px !important;
}

.ec_service_output_span {
  line-height: 1.4;
}

.ec_service_output_span.today {
  font-weight: bold;
  color: #027d88;
}

.ec_service_output_span.date {
  font-weight: bold;
}

.ec_time_hours {
  padding: 0 0 5px;
}

.ec_dyinf_no_availability {
  line-height: 1.5;
}

@media (max-width: 600px) {
  #ec_overbooked i {
    display: none;
  }
}

</style>