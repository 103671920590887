<template>

  <div class="general availability_selector">

    <!--<pre>{{filteredSlots}}</pre>-->

    <v-expansion-panels v-model="panel">

      <v-expansion-panel v-for="slot in filteredSlots" :key="slot.date">
        <v-expansion-panel-header>

          <div v-if="slot.is_today">
            <span class="ec_day_name">OGGI</span>
            {{slot.date}}
          </div>
          <div v-else>
            <span class="ec_day_name">{{ slot.date_iso | moment('dddd') }}</span>
            {{slot.date}}
          </div>

        </v-expansion-panel-header>

        <v-expansion-panel-content>

          <v-container>

            <v-layout row>

              <v-flex v-for="(time,index) in slot.times" :key="index" xs6 md4>

                <v-card class="ma-1" id="ec_avaslot_cont" v-bind:class="{ active: isActive(time, slot.date), is_not_bookable: !isBookable(time)  }" @click="selectTime(slot.date,time)"  :disabled="!isBookable(time) || cart.saving" :loading="isLoading(time)" outlined>

                  <v-card-text class="ec_availability_slot">

                    {{ time.from_time }} - {{ time.to_time }}

                  </v-card-text>

                  <v-progress-linear class="ec_avaslot_progress_line" :value="(time.placed_orders/time.max_orders*100)"></v-progress-linear>

                </v-card>

              </v-flex>

            </v-layout>

          </v-container>

        </v-expansion-panel-content>

      </v-expansion-panel>

    </v-expansion-panels>

  </div>

</template>

<script>

import {mapState} from 'vuex';

export default {

  computed: {

    ...mapState(['cart']),

    filteredSlots: function(){

      let self = this;
      let slots = [];

      self.cart.order.slots.forEach(element => {

        let day = element;
        let times = [];

        element.times.forEach(time => {

          if(time.is_in_past == false){

            times.push(time);

          }

        });

        day.times = times;

        if(day.times.length > 0){
          slots.push(day)
        }

      });

      return slots;

    }

  },

  data: () => ({

    panel: 0

  }),

  methods: {


    isActive(time, date){

      let self = this;

      let date_comp_str = 'DD.MM.Y';
      let time_comp_str = 'HH:mm';



      if(self.$moment(self.cart.order.desired_delivery_time_from).format(date_comp_str) != date){
        return false;
      }


      if(self.$moment(self.cart.order.desired_delivery_time_from).format(time_comp_str) == time.from_time){
        return true;
      }


      return false;

    },
    isLoading(time){


      if(this.cart.processing_timespan == null){return false;}

      if(
          this.cart.processing_timespan.desired_delivery_time_from == time.from &&
          this.cart.processing_timespan.desired_delivery_time_to == time.to
      ){
        return true;
      }


      return false;

    },

    isBookable(time){

      if(time.is_in_past){return false;}
      if(time.max_orders <= time.placed_orders){return false;}

      return true;

    },

    selectTime(date, time){

       this.$store.dispatch('cart/updateDesiredDelivery',{from: date+' '+time.from_time, to: date+' '+time.to_time});

    }

  }

}

</script>

<style>

.ec_day_name {
  color: #027d88;
  text-transform: capitalize;
}

.v-expansion-panel-content .container {
  padding: 0 6px 20px !important;
}

.v-expansion-panel-content__wrap {
  padding-bottom: 0;
}

.availability_selector .v-card__text {
  font-weight: bold;
  color: black !important;
}

.availability_selector .active {
  border: 2px solid #027d88 !important;
  background-color: white !important;
}

.availability_selector .active .ec_availability_slot {
  color: #027d88 !important;
}

.availability_selector #ec_avaslot_cont.active .v-progress-linear__background {
  background-color: #027d88 !important;
}

.ec_availability_slot {
  padding: 0 0 5px;
}

.ec_avaslot_progress_line {
  border-radius: 0 !important;
}

#ec_avaslot_cont {
  padding: 10px 10px 13px;
  background-color: #f6f5f5;
  border: 2px solid #f6f5f5;
}

#ec_avaslot_cont .v-progress-linear__background {
  opacity: 1 !important;
  background-color: #027d88 !important;
}

#ec_avaslot_cont .v-progress-linear__determinate {
  background-color: red !important;
}

.is_not_bookable .v-progress-linear__buffer {
  background-color: red !important;
}

.is_not_bookable .ec_availability_slot {
  padding: 0 0 5px;
  cursor: not-allowed !important;
}

.is_not_bookable .ec_avaslot_progress_line {
  opacity: 1 !important;
}

.is_not_bookable:before {
  content: "ESAURITO";
  display: flex;
  font-size: 12px;
}

</style>