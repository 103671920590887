<template>

  <div class="general shipping_methods">

    <br><strong>Consegna dell'ordine</strong>
    <br>Verifica i costi nel riassunto dell'ordine. Se disponibile il ritiro d'asporto è gratuito.

    <v-radio-group v-model="selected_method">
      <v-radio
          color="#027d88"
          :disabled="cart.processing_shipping_method == method.id"
          v-for="method in cart.shipping_methods.filter(method => method.erp_active == true)" :key="method.id"
          :label="method.name"
          :value="method.id"
          @click="selectShippingMethod(method)"></v-radio>
    </v-radio-group>

  </div>

</template>

<script>

import { mapState } from 'vuex';

export default {

  name: 'shipping_methods',
  computed: {


    ...mapState(['cart']),


  },

  data: () => ({

    selected_method: null


  }),


  mounted: function(){



    if(this.cart.order.shipping.length !== 0){

      this.selected_method = this.cart.order.shipping[0].shipping_method_id;

    }


  },


  methods: {


    isSelected(method){


      if(this.cart.order.shipping.length == 0){return false;}
      if(this.cart.order.shipping[0].shipping_method_id == method.id){return true;}

      return false;

    },

    selectShippingMethod(method){

      this.$store.dispatch('cart/selectShippingMethod',method);

    }

  }

}

</script>

<style>

.general.shipping_methods {
  grid-column: 1;
}

</style>