<template>

   <div class="ec_tile_price_container">


     <v-icon class="ec_sale_label" v-if="isOnSale">mdi-sale</v-icon>

     <div v-if="products==false">error</div>

     <div class="ec_price_wrap" v-else>

       <div class="ec_price normal" v-if="type == 'simple'">

         <currency-input v-if="isOnSale" class="old_regular_price" :value="oldRegularPrice" :currency="products.currency" locale="de" color="#000000de" disabled/>
         <currency-input :class="{is_sale_price : isOnSale}" :value="simplePrice(products)" :currency="products.currency" locale="de" color="#000000de" disabled/>

       </div>

       <div class="ec_price from" v-else-if="type == 'variable'">
         <span class="from_span old_Regular_price" v-if="oldFromPrice != null">da</span><currency-input v-if="oldFromPrice != null" :value="oldFromPrice" :currency="products[0].currency" locale="de" disabled/>
         <span class="from_span">da</span><currency-input :value="fromPrice" :currency="products[0].currency" locale="de" disabled/>
       </div>

       <div class="ec_price singular" v-else-if="type == 'variation'">
         <currency-input :value="simplePrice(products[0])" :currency="products[0].currency" locale="de" disabled/>
       </div>

     </div>
   </div>

</template>

<script>


    export default {


        props: {

            products: {default: function(){return false;}},
            product: {default: function(){return [];}},
            quantity: {default: 1},
            type: {default: 'simple'}

        },


        methods: {

          simplePrice(product) {

            if (product.price == null) {
              return null;
            }

            return product.price.price;

          },


          getPriceWithTax(price, tax_rate) {

            return (price * tax_rate / 100 + price).toFixed(2) * this.quantity;

          }

        },

      computed: {

        fromPrice() {


          let variations = JSON.parse(JSON.stringify(this.products));

          variations.sort(function (a, b) {

            return a.price.price - b.price.price

          });

          return variations[0].price.price;

        },

        oldFromPrice(){


          let variations = JSON.parse(JSON.stringify(this.products));
          variations = variations.filter(variation => variation.price.is_on_sale == true);
          if(variations.length == 0){return null;}

          variations.sort(function (a, b) {

            return a.price.regular_price - b.price.regular_price

          });


          return variations[0].price.price;


        },

          oldRegularPrice(){


            if(this.type == 'simple'){


              if(this.products.price == null){return false;}

              return this.products.price.regular_price;

            }

            return false;

          },

        isOnSale(){


          if(this.type == 'simple'){


            if(this.products.price == null){return false;}

            return this.products.price.is_on_sale;

          }


          return false;


        },

      }

    }

</script>

<style>

.ec_tile_price_container {
  grid-column: 1;
}

.ec_price {
  display: flex;
  line-height: 1.6;
}

.ec_price.from {
  display: flex;
}

.ec_price .from_span {
  padding-right: 3px;
}

#app .ec_price input {
  font-size: 14px;
  width: 50%;
}

.ec_sale_label {
  position: absolute !important;
}

.old_regular_price {
  padding-left: 30px;
  text-decoration: line-through;
  color: grey;
  opacity: 1 !important;
}

#app .is_sale_price {
  color: black !important;
  opacity: 1 !important;
}

.v-input__append-inner input {
  text-align: right !important;
}

@media (max-width: 600px) {
  .ec_price input {
    text-align: left;
    width: 100% !important;
  }
}

</style>