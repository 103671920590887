<template>

  <div class="general menu">

    <v-layout v-if="menu.loading_products" style="position: fixed; bottom: 0; left: 0; width: 100%; z-index: 9">
      <v-progress-linear indeterminate color="grey">
      </v-progress-linear>
    </v-layout>

    <div class="ec_vendor_lists_container">

      <div class="ec_vendor_lists">
        <list-selector></list-selector>
      </div>
    </div>

    <v-alert class="ec_nomenu_alert" dismissible color="amber lighten-4" v-if="menu.lists.length == 0 && menu.loading_products == false">
      Questo venditore non ha ancora caricato nessun prodotto.
    </v-alert>

    <div class="ec_vendor_menu_container">


      <div class="ec_vendor_menuselector_container"
              v-bind:class="{ is_desktop: session.client_type == 'desktop' }"
              v-stick-in-parent="stikyKit"
              v-if="getCategories.length > 0">

        <category-selector></category-selector>

      </div>

      <div class="ec_products_grid mt-3" id="product_display" :class="{'has-only-one-product': filteredProducts.length == 1}" >
        <div class="product" v-for="product in filteredProducts" :key="product.id">
          <product :product="product"></product>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { mapState, mapGetters } from 'vuex';

import product from './Product.vue'
import listSelector from './infra/list_selector'
import categorySelector from './infra/category_selector'

export default {

  components: {

    product,
    listSelector,
    categorySelector

  },

  data () {
    return {

      stikyKit: {

        options: {
          //parent: '.ec_vendor_menu_container',
          offset_top: 56
        },
      }

    }
  },

  computed: {

    ...mapState(['session']),
    ...mapState(['cart']),
    ...mapState(['menu']),
    ...mapState(['vendor_search']),
    ...mapGetters('menu',['getCategories']),



    filteredProducts: function(){


      let selected_category = this.menu.selected_category;
      let data = this.menu.products.filter(function(product) {

        let index = product.categories.findIndex(cat => cat.slug === selected_category);
        if(index === -1){return false;}
        return product;

      });

      return data;

    }

  },


  watch: {


    menu: {

      handler: function(newValue){

        if(newValue.selected_category == null){


          let categories = this.getCategories;

          if(categories.length > 0){

            this.$store.dispatch('menu/selectCategory',categories[0]);

          }

        } else if (newValue.selected_category != null){


          let categories = this.getCategories;

          let index = categories.findIndex(c => c.slug === newValue.selected_category);

          if(index == -1 && categories.length > 0){

            this.$store.dispatch('menu/selectCategory',categories[0]);

          }

        }

      },
      deep: true

    }

  },


  mounted: function(){


    this.$store.dispatch('menu/selectCategory',null);


  },

  destroyed: function(){




  },

  methods: {




  }

}

</script>

<style>

.ec_vendor_ranking i {
  font-size: 15px !important;
  padding-right: 5px;
}

.ec_vendor_lists_container {
  margin-top: -86px;
  margin-bottom: 20px;
  overflow: auto;
}

.ec_vendor_menu_container {
  float: left;
  width: 100%;
  margin: 0 0 20px;
}

.ec_vendor_menuselector_container {
  overflow: auto;
}

.ec_vendor_menuselector_container.is_stuck {
  background: #ffffff;
  width: 100vw !important;
  left: 0;
  z-index: 1;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
}

.ec_vendor_menuselector_container.is_stuck.is_desktop {
  padding-top: 6px;
}

.ec_vendor_menuselector_container.is_desktop .general.category_selector {
  max-width: 1000px;
  margin: 0 auto;
}

.has-only-one-product {
  grid-template-columns: 100% !important;
}

.has-only-one-product .ec_product_tile {
  grid-template-columns: 70% 25% !important;
}

@media (min-width: 601px) {
  .ec_products_grid {
    width: 100%;
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
  }
  .ec_nomenu_alert {
    margin-top: -20px;
  }
}

@media (max-width: 600px) {
  .ec_vendor_lists_container {
    margin-top: -75px;
  }

  .ec_vendor_menuselector_container.is_stuck .general.category_selector {
    padding-left: 15px;
  }
}

</style>