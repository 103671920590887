<template>

  <div class="ec_product_tile_container" v-bind:class="{'has_image' : hasImage}">


    <div class="ec_product_tile" :xs9="product.thumbnail != null" :xs12="product.thumbnail==null">

      <v-chip disabled class="ec_featured_product_chip" v-if="product.featured === true">
        <v-icon>mdi-fire</v-icon>
        Popolare</v-chip>

      <span class="ec_sold_out_product" v-if="!isInStock">
        Esaurito</span>

      <div class="ec_product_tile_image" :xs3="product.thumbnail != null && product.thumbnail != false" v-if="product.thumbnail != null">
        <v-img :src="product.thumbnail" :aspect-ratio="1/1" :lazy-src="require('@/assets/placeholder.png')"></v-img>
      </div>

      <span class="ec_product_tile_title">{{product.title}}</span>

      <div class="ec_prod_desc short" v-if="product.short_description != null && product.short_description != ''" v-html="product.short_description">
        {{product.short_description}}
      </div>

      <div class="ec_prod_desc full" v-if="product.description != null && product.description != ''" v-html="product.description">
        {{product.description}}
      </div>

      <div class="ec_prod_tile_attributes" v-if="product.attributes != null && product.attributes != ''">
        <ingredients :attributes="product.attributes"></ingredients>
      </div>

      <add_to_cart_button :product="product"></add_to_cart_button>

    </div>
  </div>

</template>

<script>


import add_to_cart_button from './infra/add_to_cart_button.vue'
import ingredients from './infra/ingredients'

export default {


  components: {

    add_to_cart_button,
    ingredients

  },

  props: {

    product: {default: function(){return [];}}

  },


  computed: {


    hasImage: function(){

      if(this.product.thumbnail == null){return false;}

      return true;
    },

    isInStock: function(){


      if(this.product.manage_stock === true && this.product.stock_status == 'outofstock'){


        return false;

      }



      return true;

    }

  },


}

</script>

<style>

.ec_product_tile_container {
  background-color: white;
  border-radius: 4px;
  margin-bottom: 15px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.ec_product_tile_container,
.ec_product_tile {
  float: left;
  width: 100%;
}

.ec_product_tile {
  padding: 10px;
  display: grid;
  grid-template-columns: 60% 35%;
  grid-column-gap: 5%;
  position: relative;
}

.ec_featured_product_chip {
  width: fit-content;
  margin-bottom: 5px;
  padding: 0;
  background-color: transparent !important;
  opacity: 1 !important;
  font-size: 12px !important;
  height: 26px !important;
}

.ec_featured_product_chip i {
  animation: color-change 2.5s infinite;
}

@keyframes color-change {
  0% { color: #da3856; }
  25% { color: #c13306; }
  50% { color: #cb2343; }
  75% { color: #b74e07; }
  100% { color: #da3856; }
}

.ec_product_tile_title {
  font-weight: bold;
  font-size: 16px;
  grid-column: 1 / span 2;
  padding: 0 5px 0 0;
}

.has_image .ec_product_tile_title {
  grid-column: 1 !important;
}

.ec_prod_tile_attributes {
  font-size: 13px;
  grid-column: 1;
  padding: 0 5px 0 0;
}

.ec_prod_desc {
  grid-column: 1;
  padding: 0 5px 0 0;
}

.ec_prod_desc p {
  margin: 0;
}

.ec_prod_desc {
  font-size: 13px;
  padding: 5px 0;
  grid-column: 1 / span 2;
}

.ec_prod_desc.short,
.ec_prod_desc.short p,
.ec_prod_desc.short span {
  font-weight: bold;
  color: grey !important;
  padding: 0 0 5px 0 !important;
}

.has_image .ec_prod_desc {
  grid-column-end: 1;
}

.ec_product_tile_title, .ec_prod_tile_attributes {
  width: 100%;
}

.ec_product_tile_image {
  max-width: 150px;
  grid-column: 2;
  grid-row: 1 / span 99;
}

.ec_sold_out_product {
  width: fit-content;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  background-color: #dadada;
  margin-bottom: 5px;
  border-radius: 10px;
}

.ec_product_tile_image .v-image {
  border: 1px solid #dadada;
  border-radius: 4px;
  overflow: auto;
}

@media (max-width: 600px) {
  .ec_product_tile_container {
    box-shadow: none;
    margin-bottom: 0;
    padding: 20px 10px;
    border-bottom: 1px solid #dadada;
  }
  .ec_product_tile {
    grid-template-columns: 65% 30%;
    padding: 0;
  }
  .ec_product_tile_image {
    margin: 0;
    grid-column: 2;
  }
  .ec_product_tile_image .v-image {
    border: 1px solid #dadada;
    border-radius: 4px;
    overflow: auto;
  }
  .ec_product_tile_title, .ec_prod_tile_attributes {
    justify-content: left;
    text-align: left;
    grid-column: 1;
    font-size: 15px;
    padding: 0 5px 0 0;
  }
  .ec_prod_tile_attributes div {
    text-align: left;
    font-size: 13px;
  }
}

</style>