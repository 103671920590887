<template>

  <div>

      <v-dialog v-model="dialog" max-width="600">

        <v-card class="ec_extras_dialog">
          <v-card-title class="grey lighten-2">
            Prodotto componibile
          </v-card-title>

          <v-card-text class="ec_extras_container">

            <span class="ec_extras_subtitle">
              Puoi selezionare da un minimo di {{product.min_extras}} <span v-if="product.max_extras == null">senza limite di </span><span v-else>a un massimo di {{product.max_extras}}</span> opzioni
            </span>

            <div class="ec_extra_item_cont" v-for="extra in available_extras" :key="extra.id">

              <div class="ec_extra_item" v-if="extra.is_sold_individually">

                <v-checkbox class="ec_extra_check" :label="extra.title" v-model="extra.is_selected" @change="handle_checkbox(extra)"
                            :disabled="max_quantity(extra) == 0 && extra.is_selected != true"></v-checkbox>

                <price_display class="ec_extra_price" :products="extra" v-if="price >= 0"></price_display>

              </div>

              <div class="ec_extra_item" v-else>

                <div class="ec_extra_check">
                  <span class="v-label">{{extra.title}}</span>

                  <price_display class="ec_extra_price" :products="extra"></price_display>
                </div>

                <v-text-field
                        v-model="extra.quantity"
                        type="number"
                        class="text-center ec_extra_check input" mask="d"
                        readonly
                >

                  <template v-slot:prepend-inner >
                    <v-btn icon @click="decrease_quantity(extra)" :disabled="extra.quantity == 0">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:append>

                    <v-btn icon @click="increase_quantity(extra)" :disabled="max_quantity(extra) == extra.quantity">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>

                  </template>

                </v-text-field>

                <!--
                <v-select :items="select_values(extra)" v-model="extra.quantity" :label="'Select quantity of '+extra.title"></v-select>
                -->

              </div>

            </div>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <div>


              <v-btn
                      color="primary"
                      depressed
                      tile
                      :disabled="!allow_submit"
                      @click="add_to_cart()"
              >
                Aggiungi al carrello
              </v-btn>
            </div>

          </v-card-actions>
        </v-card>
      </v-dialog>

  </div>

</template>

<script>

import price_display from "../price_display";

export default {


  name: 'extras_selector',
  components: {

    price_display

  },

  data() {
    return {
      dialog: false,
      available_extras: []
    }
  },
  props: {

    product: {default: function(){return [];}}

  },


  watch: {


    dialog: function(newValue){


      if(newValue == true){

        this.init();

      }

    }

  },

  computed: {

    allow_submit: function(){


      if(this.product.max_extras !== null && this.selected_extras_count > this.product.max_extras){return false;}


      if(this.selected_extras_count < this.product.min_extras && this.product.min_extras !== 0){return false;}

      return true;

    },

    selected_extras_count: function(){


      let extras = this.selected_extras;

      let count = 0;

      for(let i=0;i<extras.length;i++){

        if (extras[i].is_selected == true){

          count += 1;

        }

        else if(extras[i].quantity !== undefined){

          count += extras[i].quantity;

        }

      }



      return count;


    },

    selected_extras: function(){


      let extras = this.available_extras.filter(extra => (extra.quantity >= 1 || extra.is_selected == true));


      return extras;


    },

    cart_payload: function(){

      let extras = this.selected_extras;

      let payload = [];

      for(let i=0;i<extras.length;i++){


        payload.push({id: extras[i].id, quantity: extras[i].quantity})


      }

      return payload;

    }

  },



  methods: {


    openDialog(){


      this.dialog = true;

    },

    init(){


      let extras =  JSON.parse(JSON.stringify(this.product.extras));

      for(let i = 0; i<extras.length;i++){


        extras[i].quantity = 0;
        extras[i].is_selected = false;

      }

      this.available_extras = extras;

    },

    decrease_quantity(extra){

      let self = this;
      let index = this.available_extras.findIndex(xtra => xtra.id == extra.id);



      if(index !== -1){

        self.available_extras[index].quantity = self.available_extras[index].quantity-1;

      }


    },

    increase_quantity(extra){


      let self = this;
      let index = this.available_extras.findIndex(xtra => xtra.id == extra.id);



      if(index !== -1){

        self.available_extras[index].quantity = self.available_extras[index].quantity+1;

      }



    },

    select_values(extra){


      let max = this.max_quantity(extra);

      let values = [0];

      for(let i = 1; i <= max; i++){

        values.push(i)

      }



      return values;


    },

    max_quantity(extra){


      if(this.selected_extras.length == 0){

        return this.product.max_extras;

      }


      if(extra.quantity == undefined){


        extra.quantity = 0;

      }

      return extra.quantity + this.product.max_extras-this.selected_extras_count;



    },

    add_to_cart(){

      this.$emit('extras:select',this.cart_payload);
      this.dialog = false;

    },

    handle_checkbox(extra){

      if(extra.is_selected == true){

        extra.quantity = 1;

      } else {

        extra.quantity = 0;

      }

    }

  },



}

</script>

<style>

.ec_extras_container {
  padding: 0 !important;
}

.ec_extras_subtitle {
  display: inline-block;
  margin: 10px 0;
  padding: 0 10px;
}

.ec_extra_item_cont:nth-child(even) {
  background-color: #f2f2f2;
}

.ec_extra_item {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px 10px 5px;
}
.ec_extra_check {
  flex: 1 1 50% !important;
  margin: 0;
  padding: 0;
}

.ec_extra_check .v-label {
  font-weight: bold;
}

.ec_extra_check .v-input__slot {
  margin: 0;
}
.ec_extra_check.input input {
  text-align: center;
}


.ec_extra_check .v-input__slot:after,
.ec_extra_check .v-input__slot:before {
  display: none;
}

.ec_extra_price {
  text-align: left;
}

</style>