<template>

    <div>

        <div v-for="attribute in selected_filters" :key="attribute.id">

           <v-select :label="attribute.label" :items="attribute.options" item-text="label" item-value="id" v-model="attribute.selected" @change="changeAttribute()">

                <template v-slot:item="{item}">

                    {{item.label}}
                    <v-spacer></v-spacer>

                  <span class="ec_variation_selector">

                    <price_display :products="getOptionPrice(attribute, item)" :type="'variation'"></price_display>

                  </span>

                </template>

                <template v-slot:append>

                    <price_display :products="selected_variation"></price_display>

                </template>

            </v-select>

        </div>

    </div>

</template>

<script>


    import price_display from '../price_display'

    export default {

        components: {

            price_display

        },

        props: {

            product: {default: function(){return [];}}

        },

        data: () => ({

            selected_filters: [],
            selected_variation: false

        }),


        methods: {


            preselectProduct(){

                let variations = this.product.variations;
                let product = variations[0];


                for (const [key] of Object.entries(this.selected_filters)) {

                    this.selected_filters[key].selected = product.attribute_values[key];

                }

                this.changeAttribute();



            },

            changeAttribute(){


                if(this.availableProducts.length == 1){
                    this.$emit('select', this.availableProducts[0].id);
                    this.selected_variation = this.availableProducts[0];
                } else {
                    this.$emit('select', 0);
                }

            },


            getAvailableProducts(filters){

                let variations = this.product.variations;
                for (const [key, value] of Object.entries(filters)) {

                    variations = variations.filter(variation => variation.attribute_values[key] == value.selected);

                }

                return variations;


            },

            getOptionPrice(attribute, option){


                let filters = JSON.parse(JSON.stringify(this.selected_filters));

                filters[attribute.slug].selected = option.id;

                return this.getAvailableProducts(filters);

            }

        },

        computed: {


            availableProducts(){


                return this.getAvailableProducts(this.selected_filters);


            }


        },

        mounted(){


            let attributes = this.product.attributes.filter(attribute => attribute.variation == true);
            let filters = {};

            for(let j = 0; j < attributes.length; j++){

                filters [attributes[j].slug] = {id: attributes[j].id, slug: attributes[j].slug, label: attributes[j].label, selected: null, options: attributes[j].options};

            }


            this.selected_filters = filters;



            this.preselectProduct();



        }


    }

</script>

<style>

.ec_variation_selector input {
  text-align: right !important;
  flex: 1 1 100%;
}

</style>