<template>

  <div class="ec_product_tile_add_to_cart">





    <div class="ec_price_cart_bar simple" v-if="product.product_type == 'simple'">

      <price_display :products="product" :quantity="quantity" :type="'simple'"></price_display>

      <span v-if="itemsInCart !=0" class="ec_items_in_cart">{{ itemsInCart }}x</span>

      <!-- SIMPLE PRODUCT -->

      <div class="ec_addtocart_block simple">

        <input class="ec_addtocart_quantity_input" type="number" min="1" v-model="quantity" style="text-align:center;">

        <v-btn
            color="white"
            tile
            block
            @click="addToCart()"
            class="ec_addtocart_button"
            :loading="cart.processing_cart_item == product.id"
            :disabled="!allowOrders || !isInStock"
        ><v-icon>mdi-plus</v-icon></v-btn>



      </div>

      <!-- END SIMPLE PRODUCT -->

    </div>



    <!-- VARIABLE PRODUCT -->

    <div class="ec_price_cart_bar variable" v-else-if="product.product_type == 'variable'">


      <price_display :products="product.variations" :quantity="quantity" :type="'variable'"></price_display>
      <span v-if="itemsInCart !=0" class="ec_items_in_cart">{{ itemsInCart }}x</span>

      <div class="ec_addtocart_block variable">
        <v-btn tile block @click="dialog = !dialog" :disabled="!allowOrders || !isInStock">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>

    </div>

    <!-- END VARIABLE PRODUCT -->


    <!-- DIALOGS -->


    <div>

      <extras_selector :product="product" v-if="product.extras.length > 0" ref="extras_selector" @extras:select="selectExtras($event)"></extras_selector>

      <v-dialog v-model="dialog" max-width="600" v-if="product.product_type == 'variable'">
        <div class="ec_variation_thumbnail_container" v-if="product.thumbnail != null">
          <v-img class="ec_variation_thumbnail" :src="product.thumbnail" :aspect-ratio="1/1" :lazy-src="require('@/assets/placeholder.png')" max-width="100"></v-img>
        </div>

        <div class="ec_product_dialog">

          <variation_selector :product="product" @select="selectVariation($event)"></variation_selector>
          <v-btn tile block @click="addToCart()" :disabled="!allowOrders">Aggiungi</v-btn>

        </div>
      </v-dialog>

    </div>

    <!-- END DIALOGS -->

  </div>
</template>

<script>


import price_display from "./price_display";
import variation_selector from './product/variation_selector'
import extras_selector from './product/extras_selector'
import {mapGetters, mapState} from "vuex";

export default {

  components: {

    price_display,
    variation_selector,
    extras_selector

  },

  data() {
    return {

      quantity: 1,
      dialog: false,
      product_id: 0,
      variation_id: 0,
      selected_extras: []

    }
  },




  computed: {


    ...mapState(['cart']),
    ...mapGetters('cart',['allowOrders']),


    isInStock: function(){



      if(this.product.manage_stock == true && this.product.stock_status == 'outofstock'){


        return false;

      }



      return true;

    },

    itemsInCart: function(){


      let self = this;

      if(self.cart.order.length == 0 || self.cart.order.items.length == 0){
        return 0;
      }

      let counter = 0;

      let simple = self.cart.order.items.filter(item => item.product_id == self.product.id);
      let variations = self.cart.order.items.filter(item => item.master_product_id == self.product.id);


      simple.forEach(element => {

        counter = counter + element.quantity;

      });

      variations.forEach(element => {

        counter = counter + element.quantity;

      });

      return counter;

    },



  },

  props: {

    product: {default: function(){return [];}}

  },

  methods: {


    selectExtras(extras){


      this.selected_extras = extras;
      this.addToCart('extra_selector');


    },

    addToCart(source = null){


      if(this.product.extras.length > 0 && this.selected_extras.length == 0){


        if(source != 'extra_selector'){

          this.$refs.extras_selector.openDialog();
          return;

        } else {


          if(this.selected_extras_length < this.product.min_extras){
            this.$refs.extras_selector.openDialog();
            return;

          }

        }





      }





        this.$store.dispatch('cart/add', {
          product_id: this.product_id,
          variation_id: this.variation_id,
          quantity: this.quantity,
          extras: this.selected_extras
        });

        this.selected_extras = [];
        this.dialog = false;
        this.quantity = 1;



    },
    increaseQuantity(){

      this.quantity++;

    },
    decreaseQuantity(){


      this.quantity--;

    },

    selectVariation(id){

      this.variation_id = id;

    }

  },


  mounted: function(){

    this.product_id = this.product.id;

  }


}

</script>

<style lang="scss" scoped="true">

.ec_product_tile_add_to_cart {
  font-weight: bold;
  margin-top: 10px;
  grid-column: 1 / span 2;
}

.ec_addtocart_quantity_input {
  display: none;
}

@keyframes backflash {
  0% {background-color: #027d88;}
  50% {background-color: #88b0b7;}
  100% {background-color: #027d88}
}

.ec_price_cart_bar {
  display: grid;
  grid-template-columns: 65% 17.5% 17.5%;
  align-items: center;
  position: relative;
}

.ec_addtocart_block {
  grid-column: 3;
}

.ec_addtocart_block input {
  color: #545454;
  font-size: 14px;
}

.ec_addtocart_block button {
  grid-column: 3;
  box-shadow: none;
  color: #0e918c;
  font-weight: bold;
  font-size: 24px;
}

.ec_product_dialog {
  background-color: white;
  padding: 30px;
}

.ec_items_in_cart {
  width: fit-content;
  background-color: #027d88;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  animation-name: backflash;
  animation-duration: .2s;
  animation-iteration-count: 2;

  grid-column: 2;
}

.ec_variation_thumbnail_container {
  padding: 25px 25px 0;
  background-color: white;
}

.ec_variation_thumbnail {
  margin: 0 auto;
}


@media (max-width: 600px) {
  .ec_product_tile_add_to_cart {
    grid-column: 1 / span 2;
    border-top: none;
  }
  .ec_price_cart_bar {
    padding: 0 !important;
    grid-template-columns: 45% 40% 15% !important;
  }
  .ec_addtocart_block {
    grid-column: 3;
    justify-content: flex-end;
    padding-right: 5px;
    display: flex;
  }
  .ec_addtocart_block input {
    display: none !important;
  }
  .ec_addtocart_block button {
    background-color: #027d88 !important;
    color: white !important;
    border-radius: 100%;
    height: 30px !important;
    width: 30px !important;
    max-width: 30px !important;
    min-width: 30px !important;
    padding: 0 !important;
  }
  .ec_items_in_cart {
    justify-self: end;
  }
}

</style>