<template>

  <div class="general category_selector" v-if="getCategories.length > 0">

    <v-tabs class="ec_tabs" color="#027d88" v-model="tab">
      <v-tab class="ec_tab" v-for="category in getCategories" :key="category.id" @click="selectCategory(category)">{{category.label}}</v-tab>
    </v-tabs>

  </div>

</template>

<script>

    import { mapGetters, mapState } from 'vuex';

    export default {


      data () {
        return {
          tab: 0,

        }
      },

        computed: {

            ...mapState(['session']),
            ...mapGetters('menu',['getCategories']),

        },


      destroyed: function(){

        this.tab = 0;

      },

        mounted: function(){ },



        methods: {



            selectCategory(category){


              if(this.session.client_type == 'mobile'){

                var VueScrollTo = require('vue-scrollto');

                var options = {
                  container: 'body',
                  easing: 'ease-in',
                  lazy: false,
                  offset: -130,
                  force: true,
                  cancelable: true,
                  x: false,
                  y: true
                }

                VueScrollTo.scrollTo('#product_display', 500, options)

              }



                this.$store.dispatch('menu/selectCategory',category);

            }


        }

    }

</script>

<style>

.v-slide-group__wrapper {
  height: auto;
}

.v-tabs-slider-wrapper {
  height: 5px !important;
}

.v-slide-group__content {
  height: 55px;
}

.ec_tabs .v-tabs-bar {
  background-color: transparent !important;
  height: auto;
}

.ec_tabs .v-slide-group__prev,
.ec_tabs .v-slide-group__next {
  max-width: 35px;
  min-width: 35px;
  height: 50px;
}

.v-slide-group__prev--disabled,
.v-slide-group__next--disabled {
  display: none !important;
}

.ec_tabs .v-slide-group__prev {
  justify-content: left;
}

.ec_tabs .v-slide-group__prev i,
.ec_tabs .v-slide-group__next i {
  color: #027d88 !important;
}

.ec_tab {
  color: #027d88 !important;
  height: 50px;
}

.ec_tab {
  color: #027d88 !important;
  height: 50px;
}

.ec_tab.v-tab--active {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #057d8814;
}

</style>