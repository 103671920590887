<template>

  <div class="ec_ordconf_orderstatus">

    <span id="order-status" v-bind:class="cart.order.status">{{cart.order.status_text}}</span>

    <span>Consegna:</span>
    <span>{{ cart.order.shipping_method_name }}</span>
    <span>Pagamento:</span>
    <span>{{ cart.order.payment_method_name }}</span>
    <span>Giorno:</span>
    <span>{{ cart.order.desired_delivery_time_from | moment('D.M.Y') }}</span>
    <span>Fascia oraria:</span>
    <span>{{ cart.order.desired_delivery_time_from | moment('H:mm') }} - {{cart.order.desired_delivery_time_to | moment('H:mm')}}</span>


  </div>

</template>

<script>

import { mapState } from 'vuex';

export default {

  name: 'OrderStatus',
  computed: {


    ...mapState(['cart']),


  },

}

</script>

<style lang="scss">

.ec_ordconf_orderstatus {
  display: grid;
  grid-template-columns: 37% 63%;
  grid-row-gap: 5px;
  margin-top: 10px;
  padding: 10px 15px 15px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.ec_ordconf_orderstatus span {
  font-size: 15px;
}

#order-status {
  grid-column: 1 / span 2;
  width: fit-content;
  padding: 5px 0;
  font-weight: bold;
  color: #da3856 !important;
  font-size: 18px !important;
}

@media (max-width: 600px) {
  #ec_order_conf_paynow_button {
    grid-column: 1 / span 2;
  }
}


</style>