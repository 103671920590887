<template>
  <div>

    <v-card v-if="menu.loading_lists == true">

      Caricamento Menu
      <v-progress-linear indeterminate></v-progress-linear>

    </v-card>

    <v-card v-else>
      Redirect in corso
    </v-card>

  </div>
</template>

<script>

import {mapState} from "vuex";

export default {


  watch: {


    menu_available: function(newValue){


      // Comment out this block to be able to style the page.
      if(newValue){

        let self = this;

        self.$store.dispatch('menu/selectList',newValue);
        self.$router.push('/'+newValue.shop);

      }
      // End of ocmment out this block


      console.log('Menu Available: ',newValue);

    }

  },

  computed: {

    ...mapState(['menu']),
    menu_available: function(){


      let self = this;

      if(self.menu.lists.length == 0){return false;}

      return self.menu.lists.find(list => list.id == self.$route.params.id);

    }


  },

  mounted: function () {


    this.$store.dispatch('menu/init',this.$route.params.slug);


  },



}

</script>