<template>

  <v-card flat tile :loading="cart.loading" class="general cart">

    <v-layout align-center justify-center fill-height v-if="cart.loading">
      <span class="ec_cartload_span">Aggiornamento</span>
    </v-layout>

    <div class="ec_cart_container" v-else>

      <div v-if="isRestaurant && !isCheckout && session.client_type == 'desktop'" class="ec_cart vend_page desktop">

        <v-expansion-panels class="ec_expansion_panelS" v-model="cart_panels">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon>mdi-clipboard-edit-outline</v-icon>
              Vedi il tuo ordine
            </v-expansion-panel-header>

            <v-expansion-panel-content value="true">

              <div class="ec_emptycart" v-if="cart.order.items.length == 0">
                <div class="text-center mb-3">
                  <v-icon large>mdi-view-grid-plus-outline</v-icon>
                </div>
                Qui visualizzerai le tue scelte
              </div>

              <v-alert class="ec_alert_mini" color="amber lighten-4" dismissible>
                I prodotti sono elencati singolarmente per permetterti di scrivere note per ognuno di essi.
              </v-alert>

              <cart_contents></cart_contents>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </div>

      <div v-if="isRestaurant && !isCheckout && session.client_type == 'mobile'" class="ec_cart vend_page mobile">
        <div class="ec_emptycart" v-if="cart.order.items.length == 0">
          <div class="text-center mb-3">
            <v-icon large>mdi-view-grid-plus-outline</v-icon>
          </div>
          Qui visualizzerai le tue scelte
        </div>

        <cart_contents></cart_contents>

      </div>

      <div v-if="isCheckout"  class="ec_cart checkout">

        <div class="ec_emptycart" v-if="cart.order.items.length == 0">
          <div class="text-center mb-3">
            <v-icon large>mdi-view-grid-plus-outline</v-icon>
          </div>
          Qui visualizzerai le tue scelte
        </div>

        <cart_contents></cart_contents>

      </div>

        <v-btn class="ec_place_order_button" height="50px" color="#027d88" block :to="links.checkout"
               v-if="!isCheckout && cart.order.cart_total + cart.order.cart_tax >= cart.order.min_order_value && cart.order.cart_total > 0">
          PROSEGUI
          <v-icon>mdi-arrow-right-bold</v-icon>
        </v-btn>

        <div class="ec_add_products">
          <v-btn color="white" outlined block :to="links.restaurant" v-if="isCheckout">
            <v-icon>mdi-basket-plus-outline</v-icon>
            AGGIUNGI PRODOTTI
          </v-btn>
        </div>

    </div>

  </v-card>

</template>

<script>


import {mapState} from 'vuex';
import cart_contents from './infra/cart/cart_contents'


export default {

  components: {

    cart_contents

  },
  computed: {


    ...mapState(['session','cart']),


    links() {


      return {

        'checkout': '/' + this.$route.params.slug + '/checkout',
        'restaurant': '/' + this.$route.params.slug

      };

    },

    isRestaurant(){

      if(this.$route.name == 'checkout'){return true;}
      if(this.$route.name == 'restaurant'){return true;}

      return false;

    },
    isCheckout(){


      if(this.$route.name == 'checkout'){return true;}

      return false;
    },

    cart_panels: function(){


      if(this.cart.order.items.length > 0){
        return 0;
      }

      return null;

    }

  },

  methods: {},


  watch: {



  },

  data: () => ({





  }),


}

</script>

<style>

.general.cart {
  background-color: transparent;
}

.ec_cartload_span {
  font-size: 12px;
  padding: 15px 0 10px;
}

.ec_emptycart {
  text-align: center;
  font-size: 14px;
  padding: 10px 0;
}

.ec_emptycart i {
  color: #949494 !important;
}

.cart .v-expansion-panel-content__wrap {
  padding: 0;
}

.ec_expansion_panelS {
  border-radius: 6px 6px 0 0 !important;
}

.ec_expansion_panelS .v-expansion-panel-header {
  min-height: 50px !important;
  padding: 0 24px;
  align-items: center;
}

.general.cart .px-0 {
  padding: 0;
}

.ec_checkout_cart_container .ec_cart_container {
  background-color: white;
}

.ec_cart_container .ec_alert_mini {
  margin: 5px 25px 10px !important;
}

.ec_cart table {
  padding: 0 10px 10px;
}

.ec_cart table tfoot tr td {
  padding: 5px 0 !important;
  height: auto !important;
  font-size: 13px !important;
}

.ec_place_order_button {
  padding: 0;
  margin: 0 0 24px;
  text-align: right;
}

.ec_place_order_button span {
  color: white;
}

.ec_place_order_button i {
  padding-left: 5px;
  font-size: 20px !important;
}

.ec_add_products {
  padding-bottom: 10px;
}

.ec_add_products span {
  color: #027d88;
}

.ec_add_products i {
  padding-right: 5px;
  font-size: 20px !important;
}

@media (max-width: 600px) {
  .general.cart {
    padding-bottom: 100px;
  }
}


</style>