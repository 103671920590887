<template>

  <div>

    <v-overlay v-if="session.loading_vendor" opacity="1" light color="#ffffff">

      <v-layout align-center justify-center>

        <v-progress-circular indeterminate class="ma-10" size="180" color="#da024b" >
          Caricamento
        </v-progress-circular>

      </v-layout>

    </v-overlay>

    <div v-else class="general restaurant">

      <v-alert color="amber lighten-4" dismissible>
        Facile, veloce, sicuro: tutto il nostro personale è equipaggiato con dispositivi di protezione igienica.
      </v-alert>

      <v-navigation-drawer app right  width="400" :value="mobile_cart_opened"  v-if="session.client_type == 'mobile' && cart.order.length != 0">

        <v-btn x-large tile depressed dark block color="#027d88" class="ec_hide_cart_btn" @click="mobile_cart_opened = false">Nascondi carrello</v-btn>

        <cart v-if="cart.order.length != 0"></cart>

      </v-navigation-drawer>

      <div class="ec_full_white">

        <div class="ec_vendor_top_band">

          <div class="ec_vendor_info_container">

            <span class="ec_titlespan">Stai ordinando da:</span>

            <h1 class="ec_vendor_name">{{ session.vendor.name }}</h1>

            <span class="ec_vendor_claim">{{ session.vendor.claim }}</span>

            <div class="ec_mobile_grid">

            <span class="ec_vendor_address col-half">
              <v-icon>mdi-map-marker</v-icon>
            {{ session.vendor.address.address_1 }}, {{ session.vendor.address.city }}
            </span>

              <div class="ec_vendor_page_right col-full" v-if="session.user != null && session.client_type == 'mobile'">
                <dynamic-vendor-info class="dynamicinfo_mobile" v-if="cart.order.length != 0"></dynamic-vendor-info>
              </div>

            </div>

            <v-img class="ec_vendorpage_logo" :src="session.vendor.logo" lazy-src=""/>

          </div>

          <div class="ec_top_right col-full">

            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-expansion-panel-header class="ec_expansion_panel_header">
                  <v-icon>mdi-storefront</v-icon>
                  <span class="ec_vendor_expander_title">
                  Scopri {{ session.vendor.name }}
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="session.vendor.description != null">
                  {{ session.vendor.description }}
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="session.vendor.message != null && session.vendor.message != ''">
                <v-expansion-panel-header>
                  <v-icon>mdi-message-text</v-icon>
                  <span class="ec_vendor_expander_title">
                  Ultime Novità
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{session.vendor.message}}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>

          <div class="ec_vendor_info_block">
            <h3 class="ec_vendor_desc_title">
              <v-icon>mdi-silverware-clean</v-icon>
              Scegli tra i menu del momento
            </h3>
            <p class="ec_vendor_info_content">
              Consulta i servizi disponibili prima di procedere con l'ordine.
            </p>
          </div>

        </div>
      </div>

      <div class="ec_page_container">

        <div class="ec_vendor_page_left">

          <food-menu></food-menu>

          <div class="ec_vendor_info_block">

            <h3 class="ec_vendor_desc_title">

              <v-icon>mdi-information</v-icon>
              Informazioni aggiuntive

            </h3>

            <p class="ec_vendor_info_content">
              Se hai un’allergia o un’intolleranza alimentare (o se una persona per la quale stai effettuando un ordine ne ha), presta particolare attenzione agli ingredienti e relativi allergeni dei prodotti alimentari.
            </p>

            <p class="ec_vendor_info_content little">
              La gestione di magentadelivery declina ogni responsabilità in merito alla somministrazione dei prodotti acquistati presso i singoli venditori per mezzo di magentadelivery.it che siano essere relative a difetti, variazioni, errori, ritardi. Il singolo venditore è il solo responsabile nei confronti del cliente del prodotto somministrato e si assume la piena responsabilità ad adempiere ai termini di legge previsti.
            </p>

          </div>

        </div>

        <div class="ec_vendor_page_right" v-if="session.user != null && session.client_type == 'desktop'">
          <div class="ec_sidebar_container">

            <div class="ec_sidebar_cart_container">
              <cart v-if="cart.order.length != 0"></cart>
            </div>

            <dynamic-vendor-info v-if="cart.order.length != 0"></dynamic-vendor-info>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

import FoodMenu from '../../components_frontend/Restaurant/Menu'
import Cart from '../../components_frontend/Restaurant/Cart'
import DynamicVendorInfo from '../../components_frontend/Restaurant/DynamicVendorInfo'

import {mapState} from 'vuex';

export default {

  name: 'Restaurant',
  metaInfo () {
    return {
      title: this.pageTitle
    }
  },

  components: {

    FoodMenu,
    Cart,
    DynamicVendorInfo
  },

  computed: {

    ...mapState(['session', 'menu', 'cart']),

    pageTitle: function(){

        let self = this;

        if(self.session.vendor == null){return 'Magenta Delivery';}
        return self.session.vendor.name + ' accetta prenotazioni digitali solo su magentadelivery.it';

    },

  },

  data: () => ({

    mobile_cart_opened: false

  }),


  methods: {


    resetMenu: function(){

      this.$store.commit('cart/RESET');
      this.$store.commit('menu/RESET');

    },

    initMenu: function(){


      this.$store.dispatch('menu/init',this.$route.params.slug);

    }

  },

  mounted: function () {

    let vendor = this.$route.params.slug;


    let self = this;

    this.$root.$on('toggle-mobile-cart', function(){

      self.mobile_cart_opened = ! self.mobile_cart_opened;

    });

    this.$store.dispatch('session/loadVendor', vendor);

    if(this.cart.order.status != 'cart'){
      this.resetMenu();
    }

    this.resetMenu();
    this.initMenu();


  },

  destroyed: function(){

    this.$store.dispatch('session/resetVendor');

  }


}

</script>

<style>

.ec_vendor_top_band {
  max-width: 1000px;
  padding: 25px 0 65px;
  margin: 0 auto;
  position: relative;
  display: grid;
  grid-template-columns: 66% 34%;
}

.ec_vendor_info_container {
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 25px;
}

.ec_vendorpage_logo {
  grid-column: 1;
  grid-row: 1 / span 999;
  max-width: 125px;
  max-height: 125px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: white;
  border-radius: 4px;
}

.ec_titlespan {
  grid-column: 2;
  grid-row: 1;
}

.ec_vendor_name {
  grid-column: 2;
  grid-row: 2;
  line-height: 1.2;
  text-transform: uppercase;
  font-size: 26px;
  margin-bottom: 5px;
}

.ec_vendor_claim {
  grid-column: 2;
  grid-row: 3;
  padding-bottom: 10px;
}

.ec_vendor_address {
  grid-column: 2;
  grid-row: 4;
  font-size: 14px;
  padding-bottom: 5px;
}

.ec_top_right {
  padding-left: 15px;
  margin-bottom: 30px;
}

.ec_vendor_expander_title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 15px;
  text-align: right;
}

.v-expansion-panel-content__wrap {
  white-space: pre-line;
  padding-bottom: 10px;
}

.ec_page_container {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
}

.ec_vendor_page_left {
  width: 60%;
}

.ec_vendor_info_block {
  padding-top: 50px;
}

.ec_vendor_desc_title {
  padding-top: 5px;
  font-weight: bold;
  width: 100%;
  overflow: auto;
}

.ec_vendor_desc_title i {
  padding-right: 10px;
  float: left;
}

.ec_vendor_info_content {
  font-size: 14px;
  width: 100%;
}
.ec_vendor_info_content p {
  margin-bottom: 10px;
}

.ec_vendor_info_content.little {
  font-size: 10px;
}

.ec_vendor_page_right {
  width: 40%;
  padding-left: 75px;
}

.ec_sidebar_cart_container {
  margin-top: -147px;
}

@media (min-width: 601px) {

  .ec_mobile_grid {
    grid-template-rows: auto auto;
  }

  .ec_mobile_grid .col-half {
    display: flex;
    align-items: center;
  }

  .ec_mobile_grid .col-half i {
    padding-right: 10px;
  }

  .ec_nomenu_alert {
    margin-top: -20px !important;
  }
}

@media (max-width: 1049px) {

  .ec_vendorpage_logo {
    width: 100px !important;
    left: 50%;
    margin-left: -50px;
    grid-row: 1;
    margin-bottom: 10px;
  }

  .ec_vendor_top_band {
    padding: 15px 0;
    grid-template-columns: 100%;
  }

  .ec_vendor_top_band,
  .ec_vendor_info_container {
    width: 100%;
  }

  .ec_vendor_info_container {
    position: relative;
    grid-template-columns: 100%;
    text-align: center;
    padding: 0 10px 10px;
  }

  .ec_titlespan,
  .ec_vendor_name,
  .ec_vendor_claim {
    grid-column: 1;
    grid-row: auto;
  }

  .ec_mobile_grid {
    display: grid;
    grid-template-columns: 100%;
  }

  .ec_mobile_grid .col-half {
    grid-row: 1;
  }

  .ec_mobile_grid span i {
    width: 100%;
    padding-bottom: 10px;
  }

  .ec_mobile_grid span i:after {
    width: auto !important;
  }

  .ec_vendor_address {
    grid-column: 1;
  }
}


@media (min-width: 601px) and (max-width: 1049px) {

}


@media (max-width: 600px) {

  .ec_vendor_name {
    font-size: 20px;
  }

  .ec_vendor_page_left,
  .ec_vendor_page_right {
    width: 100%;
  }

  .col-full {
    grid-column: 1 / span 2;
  }

  .ec_vendor_desc_title {
    padding-bottom: 10px;
    font-size: 16px;
  }

  .ec_top_right {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 0;
  }

  .dynamicinfo_mobile {
    margin: 10px 0 0 !important;
    width: 100%;
  }

  .ec_vendor_info_block {
    padding: 35px 15px;
  }

  .general.restaurant .ec_page_container {
    padding-bottom: 0;
  }

  .ec_vendor_expander_title {
    font-size: 12px !important;
  }

}

</style>