<template>

    <div>

      <v-snackbar bottom light v-model="cart.show_errors" v-for="error in cart.order.ready_for_checkout.errors" :key="error.code"  :timeout="timeout">

        {{ error.message }}

        <template v-slot:action="{ attrs }">
          <v-btn color="#027d88" text v-bind="attrs" @click="cart.show_errors = false">
            Chiudi
          </v-btn>
        </template>
      </v-snackbar>

     
    </div>

</template>

<script>

    import { mapState } from 'vuex';

    export default {

        name: 'order_errors_snackbar',
        computed: {

            ...mapState(['cart']),
        },

        methods: {

        },

      data() {
        return {
          timeout: 3000
        }
      }
    }

</script>

<style>

.v-snack__wrapper {
  border: 3px solid #da3856 !important;
  border-radius: 5px !important;
  flex-wrap: wrap;
  padding: 15px 10px;
}

.v-snack__content {
  background-color: white;
  color: #da3856;
  font-weight: bold;
}

@media (max-width: 600px) {
  .v-snack__wrapper {
    width: 100%;
    padding: 10px;
    margin: 0 !important;
    border: none !important;
  }
  .v-snack__content {
    font-size: 12px !important;
  }
}

</style>