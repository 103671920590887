<template>

  <div class="ec_page_container overflowed">

    <div v-if="cart.order == false">

      <vendor-data class="ec_ordconf_vend_data_container"></vendor-data>

      <div class="ec_order_not_found_container">
        <span class="ec_order_not_found_span">
          Ordine non trovato!
        </span>

      <router-link class="ec_co_backtovend"
                   :to="{ name: 'restaurant', params: { slug: $route.params.slug }}">
        <v-btn outlined color="#027d88">
          Torna alla pagina del venditore
        </v-btn>
      </router-link>
      </div>
    </div>


    <div style="display: flex; flex-wrap: wrap; padding: 1px;" v-else>

      <div class="ec_ordconf_container left">

        <div class="ec_order_forwarded">
          <h2>
            <v-icon>mdi-check-bold</v-icon>
            Perfetto!</h2>

          <div class="ec_paymeth_instructions" v-if="cart.order.status == 'pending' && cart.order.payment_link !== null" v-html="cart.order.payment.instruction"></div>

          <v-btn color="#027d88" depressed id="ec_order_conf_paynow_button" :href="cart.order.payment_link" v-if="cart.order.status == 'pending' && cart.order.payment_link !== null">EFFETTUA PAGAMENTO</v-btn>

          <h2>
            <v-icon>mdi-clipboard-flow</v-icon>
            Stato del tuo ordine
            <v-btn x-small outlined color="#027d88" class="ec_orderconf_refresh_button" @click="refreshStatus()">Aggiorna</v-btn>
          </h2>

          <order-status class="ec_ordconf_order_status_container"></order-status>

          <p><strong>Tieni aperta questa pagina</strong> o presta attenzione alle <strong>notifiche via email</strong> per rimanere aggiornato sullo stato dell'ordine.</p>

        </div>

      </div>

      <div class="ec_ordconf_container right">

        <vendor-data class="ec_ordconf_vend_data_container"></vendor-data>

        <order-contents class="ec_ordconf_order_contents_container"></order-contents>

      </div>
    </div>
  </div>


</template>

<script>

import {mapState} from "vuex";
import VendorData from '@/components_frontend/OrderStatus/VendorData'
import OrderContents from '@/components_frontend/OrderStatus/OrderContents'
import OrderStatus from '@/components_frontend/OrderStatus/OrderStatus'

export default {

  components: {

    VendorData,
    OrderContents,
    OrderStatus


  },

  metaInfo () {
    return {
      title: this.pageTitle
    }
  },

  computed: {


    ...mapState(['session','cart']),
    pageTitle: function(){


      let self = this;

      if(self.session.vendor == null){return 'Magenta Delivery';}
      return 'Il tuo ordine per ' +self.session.vendor.name + ' | magentadelivery.it';


    },

  },


  data: () => ({

    refresh_interval: null

  }),

  methods: {


    refreshStatus(){


      this.$store.dispatch('cart/loadCartWithToken', this.$route.params.public_token)

    }

  },

  destroyed: function(){


    let self = this;

    clearInterval(self.refresh_interval);


  },

  mounted: function(){

    let self = this;
    let vendor = this.$route.params.slug;

    this.$store.dispatch('session/loadVendor',vendor);
    this.$store.dispatch('cart/loadCartWithToken', this.$route.params.public_token);


    this.refresh_interval = setInterval(function(){self.refreshStatus()}, 30000);


  }



}


</script>

<style>

.ec_order_not_found_container {
  padding: 10px;
  min-height: 500px;
}

.ec_order_not_found_span {
  font-weight: bold;
  padding-right: 10px;
}

.ec_ordconf_container h2 {
  display: flex;
  align-items: center;
  padding: 0 0 15px;
}

.ec_ordconf_container h2 i {
  padding-right: 10px;
}

.ec_ordconf_container.left {
  padding: 0 15px 15px 0;
  width: 55%;
  margin-right: 15%;
}

.ec_ordconf_container.right {
  width: 30%;
  height: fit-content;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.ec_ordconf_vend_data_container,
.ec_ordconf_order_status_container,
.ec_ordconf_order_contents_container {
  background-color: white;
}

.ec_order_forwarded h2 {
  font-weight: bold;
  line-height: 1;
}

.ec_orderconf_refresh_button {
  margin-left: 10px;
}

.ec_ordconf_order_status_container {
  margin: 10px 0 25px;
}

#ec_order_conf_paynow_button {
  color: white !important;
  margin: 10px 0 30px;
}

@media (max-width: 600px) {
  .ec_ordconf_container {
    width: 100% !important;
    padding: 0 !important;
    margin-right: 0 !important;
  }
  .ec_paymeth_instructions {
    font-size: 14px;
  }
  .ec_ordconf_container.left {
    margin-bottom: 10px !important;
  }
  .ec_ordconf_container h2 {
    font-size: 18px;
    line-height: 1.4;
  }
}

</style>