<template>

  <div>

    <div class="ec_vendor_dynamicinfo" v-if="session.client_type == 'desktop'">

      <div class="DYNAMIC_INFO_DESKTOP">

        <div class="ec_dynamicinfo_header">

          <h4>
            Servizi disponibili
          </h4>

        </div>

        <div v-if="menu.selected_list != null">


          <div v-if="menu.selected_list.time_to_prepare_hours > 0 && menu.selected_list.time_to_prepare_hours < 0.9" class="ec_dynamicinfo_output_block output">
            <i aria-hidden="true" class="v-icon mdi mdi-room-service"></i>
            <span class="ec_dynamicinfo_title">Tempo di preparazione</span>
            <span class="ec_service_output">Mediamente {{menu.selected_list.time_to_prepare}} min</span>
          </div>

          <div v-if="menu.selected_list.time_to_prepare_hours > 0.9 && menu.selected_list.time_to_prepare_hours < 1.9" class="ec_dynamicinfo_output_block output">
            <i aria-hidden="true" class="v-icon mdi mdi-room-service"></i>
            <span class="ec_dynamicinfo_title">Tempo di preparazione</span>
            <span class="ec_service_output">Mediamente {{menu.selected_list.time_to_prepare_hours}} ora</span>
          </div>

          <div v-if="menu.selected_list.time_to_prepare_hours > 1.9 && menu.selected_list.time_to_prepare_hours < 3" class="ec_dynamicinfo_output_block output">
            <i aria-hidden="true" class="v-icon mdi mdi-room-service"></i>
            <span class="ec_dynamicinfo_title">Tempo di preparazione</span>
            <span class="ec_service_output">Mediamente {{menu.selected_list.time_to_prepare_hours}} ore</span>
          </div>

        </div>

        <div class="ec_dynamicinfo_output_block output">
          <i aria-hidden="true" class="v-icon mdi mdi-shopping"></i>
          <span class="ec_dynamicinfo_title">Modalità di consegna</span>
          <span class="ec_service_output" v-for="shipping_method in cart.shipping_methods"
                :key="shipping_method.id">
            {{ shipping_method.name }}
        </span>
        </div>

        <div class="ec_dynamicinfo_output_block output">
          <i aria-hidden="true" class="v-icon mdi mdi-calendar-clock"></i>
          <span class="ec_dynamicinfo_title">Orari</span>


          <delivery_hours/>


        </div>

        <div class="ec_dynamicinfo_output_block output">
          <i aria-hidden="true" class="v-icon mdi mdi-city"></i>
          <span class="ec_dynamicinfo_title">Città servite<br>con consegna a domicilio</span>
          <span class="ec_service_output" v-for="city in cart.served_cities" :key="city">
            {{ city }}
            </span>
        </div>

      </div>

    </div>



    <div class="DYNAMIC_INFO_MOBILE" v-if="session.client_type == 'mobile'">

      <v-bottom-sheet v-model="bottom_sheet">

        <v-card tile>

          <v-card-title class="ec_closing_footmob">

            <v-spacer></v-spacer>
            <v-btn @click="closeSheet()" icon x-small>
              <v-icon>mdi-close</v-icon>
            </v-btn>

          </v-card-title>

          <v-card-text class="ec_footmob_content">

            <div v-if="selected_view == 'availability'" class="ec_dynamicinfo_output_block">

              <div class="ec_bottom_dyinfsection">

                <span class="ec_dynamicinfo_title">
                  <v-icon>mdi-calendar-clock</v-icon>
                  Fasce orarie
                </span>

                <delivery_hours/>

              </div>

            </div>

            <div v-if="selected_view == 'conditions'"  class="ec_dynamicinfo_output_block">

              <div class="ec_bottom_dyinfsection">

                <span class="ec_dynamicinfo_title">
                  <v-icon>mdi-cash-usd-outline</v-icon>
                  Ordine minimo
                </span>

                <span class="ec_service_output">
                  <currency-input :value="cart.order.min_order_value"
                                  :currency="cart.currency" locale="de" disabled/>
                </span>

              </div>

              <div class="ec_bottom_dyinfsection">

                <span class="ec_dynamicinfo_title">
                  <v-icon>mdi-city</v-icon>
                  Città servite con consegna a domicilio
                </span>

                <div class="ec_dyinf_cities">
                <span class="ec_service_output" v-for="city in cart.served_cities" :key="city" @click="selectCity(city)">
                  {{ city }}
                </span>
                </div>

              </div>

              <div class="ec_bottom_dyinfsection">

                <span class="ec_dynamicinfo_title">
                  <v-icon>mdi-sale</v-icon>
                  Ritiro gratuito<br>
                  secondo disponibilità
                </span>

                <div class="ec_dyinf_cities">
                <span class="ec_service_output">
                  Puoi risparmiare sui costi di consegna selezionando il ritiro gratuito su appuntamento!
                </span>
                </div>

              </div>

              <div class="ec_bottom_dyinfsection" v-if="service_fee != 0 && service_fee != null">

                <span class="ec_dynamicinfo_title">
                  <v-icon>mdi-room-service</v-icon>
                  Contributo di servizio
                </span>

                <span class="ec_service_output">
                  <currency-input :value="service_fee" :currency="cart.currency" locale="de"
                                  disabled/>
                </span>
              </div>

            </div>

          </v-card-text>

        </v-card>

      </v-bottom-sheet>

      <v-bottom-navigation class="ec_footmob"  grow app>

        <v-btn @click="selectView('availability')">
          <span>Disponibilità</span>
          <v-icon>mdi-calendar-clock</v-icon>
        </v-btn>

        <v-btn @click="selectView('conditions')">
          <span>Servizio</span>
          <v-icon>mdi-room-service</v-icon>
        </v-btn>

        <v-btn
            color="#027d88"
            :to="links.checkout"
            v-if="session.client_type == 'mobile' && readyForCheckout" class="ec_place_order_button restaurant">
          PROSEGUI
          <v-icon>mdi-arrow-right-bold</v-icon>
        </v-btn>

      </v-bottom-navigation>

    </div>

  </div>

</template>

<script>

import {mapState, mapGetters} from 'vuex';

import delivery_hours from "@/components_frontend/Restaurant/infra/DynamicVendorInfo/delivery_hours";

export default {


  name: 'DynamicVendorInfo',
  computed: {

    ...mapState(['session', 'menu', 'cart']),
    ...mapGetters('cart',['bookableDays']),
    ...mapGetters('cart',['cartHasSlotsToday']),
    service_fee: function(){

      let self = this;
      let fee = 0;

      if(self.cart.order.fees.length == 0){return self.fee;}

      self.cart.order.fees.forEach(element => {

        fee += element.total_with_tax;

      });


      return fee;

    },
    readyForCheckout() {


      if(this.cart.order.cart_total + this.cart.order.cart_tax >= this.cart.order.min_order_value &&
          this.cart.order.cart_total > 0){return true;}

      return false;

    },
    links() {


      return {

        'checkout': '/' + this.$route.params.slug + '/checkout',
        'restaurant': '/' + this.$route.params.slug

      };

    },

  },
  components: {

    delivery_hours

  },

  data: () => ({

    selected_view: null,
    bottom_sheet: false


  }),


  watch: {




  },


  mounted: function(){

    this.$store.dispatch('cart/getMethods');
    this.$store.dispatch('cart/loadServedCities');

  },

  methods: {


    selectView(view){



      this.selected_view = view;
      this.bottom_sheet = true;

    },

    selectCity(city){


      this.cart.order.billing_address.city = city;
      this.cart.order.shipping_address.city = city;

      this.$store.dispatch('cart/updateAddress',{billing_address: this.cart.order.billing_address, shipping_Address: this.cart.order.shipping_address});


    },

    closeSheet(){


      this.bottom_sheet = false;

    },

    isSelectedShippingMethod(method){

      if(this.cart.order.shipping.length == 0){return false;}
      if(this.cart.order.shipping[0].shipping_method_id == method.id){return true;}

      return false;

    },

    selectShippingMethod(method){

      this.$store.dispatch('cart/selectShippingMethod',method);

    },

    isSelectedPaymentMethod(method){

      if(method.slug == this.cart.order.payment_method){return true;}

    },
    selectPaymentMethod(method){


      this.$store.dispatch('cart/selectPaymentMethod',method);

    }
  },
}

</script>

<style>

.ec_vendor_dynamicinfo {
  background-color: white;
  border-radius: 5px;
  padding: 25px;
  display: flow-root;
  margin-bottom: 15px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.ec_vendor_dynamicinfo .output span {
  margin: 4px 0;
}

.DYNAMIC_INFO_DESKTOP .ec_dynamicinfo_output_block {
  display: grid;
  grid-auto-flow: column;
  grid-auto-rows: auto;
  grid-template-columns: [left] 40px [right] auto;
  grid-column-gap: 25px;
  justify-content: left;
  align-items: center;
  margin-bottom: 10px;
}

.ec_dynamicinfo_header {
  padding-bottom: 8px;
  margin-bottom: 15px;
  border-bottom: 1px solid #dadada;
}

.ec_dynamicinfo_header span {
  font-size: 14px;
}

.ec_dynamicinfo_output_block i {
  color: #585858;
  grid-column: left;
  justify-self: center;
}

.ec_dynamicinfo_title {
  grid-area: auto / right;
  font-weight: bold;
  color: #da3856;
}

.ec_service_output {
  grid-area: auto / right;
  font-size: 14px;
  line-height: 1;
  position: relative;
}

.ec_service_output.selectable {
  cursor: pointer;
}

.ec_service_output.selectable i {
  font-size: 14px !important;
  position: absolute !important;
  left: -18px;
  top: 0;
  color: #027d88 !important;
}

.ec_footmob button {
  max-width: 100% !important;
  color: #027d88 !important;
}

.ec_footmob i {
  padding-bottom: 5px !important;
}

.ec_closing_footmob {
  background-color: #dadada;
  padding: 10px 25px !important;
}

.ec_footmob_content {
  padding: 20px 25px !important;
}

@media (max-width: 600px) {
  .ec_footmob {
    height: 90px !important;
  }
  .ec_bottom_dyinfsection {
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 46% 50%;
    grid-column-gap: 4%;
    align-items: baseline;
    border-bottom: 1px dotted #dadada;
  }
  .ec_dynamicinfo_title,
  .ec_service_output {
    grid-area: auto;
  }
  .ec_dynamicinfo_title {
    grid-column: 1;
    padding-left: 35px;
    position: relative;
    line-height: 1.5;
  }
  .ec_dynamicinfo_title i {
    position: absolute !important;
    left: 0;
  }
  .ec_service_output {
    grid-column: 2;
    display: list-item;
    list-style: none;
    line-height: 1.5;
  }
  .v-dialog,
  .v-dialog__content {
    overflow-y: scroll !important;
  }
}

</style>