<template>


    <div class="ingredient-list">

        {{ingredients}}

    </div>

</template>


<script>


    export default {

        props: {

            attributes: {default: function(){return [];}}

        },


        computed: {


            ingredients(){


                let data = [];


                for(let i = 0; this.attributes.length > i; i++ ){



                    if(this.attributes[i].attribute_group.slug == 'ingredients'){

                        for(let j = 0; this.attributes[i].options.length > j; j++){

                            data.push(this.attributes[i].options[j]['label']);

                        }

                    }


                }


                return data.join(', ');

            }

        }


    }

</script>