<template>
  <div class="general RestaurantCheckout ec_page_container">

    <v-overlay v-if="cart.checking_out">
      Ordine in elaborazione
      <v-progress-linear indeterminate color="pink"></v-progress-linear>
    </v-overlay>

    <v-container class="ec_checkout_form_container">

      <div v-if="cart.loading == false">

        <div class="ec_body_container">


          <div v-if="cart.order.status != 'cart'">

            <v-progress-linear indeterminate></v-progress-linear>
            <v-card flat>

              <v-card-text class="py-5">


                Reindirizzamento in corso...
                Se l'attesa è troppo lunga per favore <a :href="cart.order.payment_link">clicca qui</a>


              </v-card-text>


            </v-card>

          </div>

          <div v-else>

            <checkout></checkout>

          </div>

        </div>

      </div>

    </v-container>

    <cart class="ec_checkout_cart_container" v-if="session.client_type == 'desktop' && cart.order.length != 0"></cart>

    <v-navigation-drawer class="ec_checkout_cart_container" app right  width="400" :value="mobile_cart_opened"  v-if="session.client_type == 'mobile' && cart.order.length != 0">

      <v-btn  x-large tile depressed dark block color="#027d88" class="ec_hide_cart_btn" @click="mobile_cart_opened = false">Nascondi carrello</v-btn>
      <cart v-if="cart.order.length != 0"></cart>

    </v-navigation-drawer>

  </div>
</template>

<script>

import {mapState} from 'vuex';
import Cart from '../../components_frontend/Restaurant/Cart'
import Checkout from '../../components_frontend/Restaurant/Checkout'

export default {

  components: {

    Cart,
    Checkout

  },

  metaInfo () {
    return {
      title: this.pageTitle
    }
  },

  computed: {

    ...mapState(['session', 'cart']),
    status_page_link: function () {


      let self = this;

      return '/' + self.cart.shop + '/checkout/confirm/' + self.cart.order.public_token;

    },

    pageTitle: function(){

      let self = this;

      if(self.session.vendor == null){return 'Magenta Delivery';}
      return self.session.vendor.name + ' Checkout | magentadelivery.it';

    },

  },

  data: () => ({

    mobile_cart_opened: false

  }),

  mounted: function () {

    let self = this;

    this.$store.dispatch('cart/loadCart');

    this.$root.$on('toggle-mobile-cart', function(){

      self.mobile_cart_opened = ! self.mobile_cart_opened;

    });

  },

  watch: {

    cart: {
      handler: function (new_value) {


        if (new_value.order.status != 'cart') {



          if(new_value.order.status == 'pending'  && new_value.order.payment_link != null){

            window.location.href = new_value.order.payment_link;
            return;

          } else {

            let self = this;
            self.$router.push(self.status_page_link);

          }




        }


      }, deep: true
    }

  }

}

</script>

<style>

.RestaurantCheckout {
  display: flex;
}

.ec_checkout_form_container {
  width: 70%;
  padding: 0 15px 0 0;
}

.ec_checkout_cart_container {
  width: 30%;
  padding: 0;
  height: 100%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

@media (max-width: 600px) {
  .general.RestaurantCheckout {
    padding: 0;
  }
  .ec_checkout_form_container,
  .ec_checkout_cart_container {
    width: 100%;
    padding: 0;
  }
}

</style>