<template>
    <div class="general list_selector">
        <div class="ec_list_selector">

          <v-btn outlined color="#027d88" class="ec_list_button" v-for="list in menu.lists" :key="list.id"

                   :class="{ active: isActive(list) }"  :disabled="list.product_count == 0"
                    @click="selectList(list)" depressed>

            <v-icon left v-if="isActive(list)">mdi-check</v-icon>

            {{list.name}}
          </v-btn>

        </div>
    </div>
</template>

<script>

    import {mapState} from 'vuex';

    export default {


        computed: {

            ...mapState(['menu'])

        },
        methods: {


          isActive(list){

            let self = this;

            if(list == null){return false;}
            if(list.id == self.menu.selected_list.id){
              return true;
            }

            return false;

          },

            selectList(list){

                this.$store.dispatch('menu/selectList',list);


            },

        }

    }

</script>

<style>

.ec_list_button {
  margin-right: 5px;
}

@media (max-width: 600px) {
  .ec_list_selector {
    display: inline-flex;
  }
}

</style>

